import { useEffect, useState } from "react";
import TextInput from "./Input/TextInput";
import Api from "./Api";
import ItemList from "./item/ItemList";

function App() {
    let [password, setPassword] = useState("");
    let [page, setPage] = useState(1);
    let [expire, setExpire] = useState(7 * 24 * 60 * 60);
    let [count, setCount] = useState(12);
    let [data, setData] = useState([]);
    let [server, setServer] = useState("cn");
    let [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem("password")) {
            let _password = prompt("Enter password");
            setPassword(_password);
            localStorage.setItem("password", _password);
        } else {
            setPassword(localStorage.getItem("password"));
        }
    }, []);

    async function load() {
        setData([]);
        let api = new Api(password);
        let res = await api.getHistory({
            page: page,
            expire: expire * 1000,
            count: count,
            server: server,
        });
        if (!res.success) {
            alert(res.message);
            return;
        }
        setData(res.data);
        setLoaded(true);
    }

    async function deleteAll() {
        setData([]);
        let api = new Api(password);
        let res = await api.deleteHistorys(data.map((v) => {
            return v.id;
        }));
        if (!res.success) {
            alert(res.message);
            return;
        }
        load();
    }

    const options = [
        {
            label: "CN",
            value: "cn",
        },
        {
            label: "TW",
            value: "tw",
        },
        {
            label: "KO",
            value: "ko",
        }
    ];


    return (
        <div className="min-h-screen bg-slate-800 text-white py-12">
            {
                !loaded ?
                    <div className="bg-white text-black rounded p-4 max-w-xs mx-auto">
                        <TextInput value={page} label={"頁數"} onChange={setPage}></TextInput>
                        <TextInput value={expire} label={"多久時間內(秒)"} onChange={setExpire}></TextInput>
                        <TextInput value={count} label={"每頁數量"} onChange={setCount}></TextInput>
                        <TextInput type="select" options={options} value={server} label={"伺服器"} onChange={setServer}></TextInput>
                        <button onClick={load} className="mt-4 w-full rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            顯示
                        </button>
                    </div>
                    :
                    <div>
                        <ItemList data={data}></ItemList>
                        <div className="text-center flex">
                            <button onClick={deleteAll} className="my-12 w-full max-w-xs mx-auto rounded-md bg-red-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                                刪除全部並刷新
                            </button>
                            <button onClick={load} className="my-12 w-full max-w-xs mx-auto rounded-md bg-indigo-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                刷新
                            </button>
                        </div>
                    </div>
            }
        </div>
    );
}

export default App;
