import { useState } from "react";
import DetailModal from "./DetailModal";
import Api from "../Api";

export default function Item({ data }) {
    let [showDetail, setShowDetail] = useState(false);
    let [deleted, setDeleted] = useState(false);

    async function del(e) {
        e.stopPropagation();
        let api = new Api(localStorage.getItem("password"));
        let res = await api.deleteHistory(data.id);
        if (!res.success) {
            alert(res.message);
            return;
        }
    }

    return <div className={"w-full" + (deleted ? " opacity-20" : " cursor-pointer")} onClick={() => {
        if (deleted) {
            return;
        }
        setShowDetail(true)
    }}>
        <div className="relative w-full">
            <img className="w-full pointer-events-none" loading="lazy" src={`https://ui.umaevents.io/${data.path}`} alt="" />
            <button onClick={(e) => {
                del(e);
                setDeleted(true)
            }} className="w-fit absolute bottom-0 right-0 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">刪除</button>
        </div>
        <DetailModal item={data} show={showDetail} onDelete={() => {
            setDeleted(true);
        }} onClose={setShowDetail}></DetailModal>
    </div>
}