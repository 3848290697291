import Item from "./Item"

export default function ItemList({ data }) {
    return <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-7xl mx-auto">
        {
            data.map((item) => {
                return <Item key={item.id} data={item}></Item>
            })
        }
    </div>
}