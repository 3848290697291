import { useEffect, useState } from "react";
import Api from "../Api";

const colors = [
    "bg-[#E9FFC1]",
    "bg-[#FFFBDA]",
    "bg-[#FFDDEC]",
    "bg-[#D9F5FF]",
    "bg-[#DDE1FF]",
];

export default function DetailModal({ item, show, onClose, onDelete }) {
    let [data, setData] = useState(null);

    useEffect(() => {
        if (show && item) {
            load();
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [show, item]);

    async function load() {
        let api = new Api(localStorage.getItem("password"));
        let res = await api.getHistoryString(item.id);
        if (!res.success) {
            alert(res.message);
            return;
        }
        setData(res.data);
    }

    async function deleteItem() {
        onDelete();
        onClose(false);
        let api = new Api(localStorage.getItem("password"));
        let res = await api.deleteHistory(item.id);
        if (!res.success) {
            alert(res.message);
            return;
        }
    }

    function copy(s) {
        navigator.clipboard.writeText(s);
    }

    if (!show) {
        return <></>
    }

    return <div className="fixed z-50 top-0 left-0 right-0 bottom-0 overflow-y-auto bg-black/50 cursor-default" onClick={(e) => {
        e.stopPropagation();
        onClose(false);
    }}>
        <div onClick={(e) => {
            e.stopPropagation();
        }} className="w-full max-w-sm mx-auto p-4 my-12 bg-white rounded">
            <div>
                <img className="w-full object-contain p-4" src={`https://ui.umaevents.io/${item.path}`} alt="" />
            </div>
            {
                data && data.map((s, i) => {
                    return <div key={i} className={"px-4 py-2 border-b flex flex-wrap" + (colors[i])}>
                        <div className="text-gray-500">{s}</div>
                        <button className=" select-none text-indigo-600 hover:text-indigo-500 active:text-indigo-300 underline pl-4 cursor-pointer text-xs" onClick={copy.bind(this, s)}>Copy</button>
                    </div>
                })
            }
            <button onClick={() => {
                deleteItem();
            }} className="mt-4 w-full rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">刪除</button>
        </div>
    </div>
}