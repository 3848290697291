import { useId } from "react"

export default function TextInput({
    label,
    placeholder,
    value = "",
    onChange = () => { },
    type = "text",
    disabled = false,
    options = [],
}) {
    let id = useId();
    let input;
    if (type == "textarea") {
        input = <textarea
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            name={id}
            id={id}
            rows={6}
            autoComplete="off"
            disabled={disabled}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}></textarea>;
    } else if (type == "select") {
        input = <select
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            name={id}
            id={id}
            autoComplete="off"
            disabled={disabled}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}>
            {
                options.map((v) => {
                    return <option key={v.value} value={v.value}>{v.label}</option>
                })
            }
        </select>;
    } else {
        input = <input
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            type={type}
            name={id}
            id={id}
            autoComplete="off"
            disabled={disabled}
            className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}
        />;
    }
    return <div>
        <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900 select-none">
            {label}
        </label>
        <div className="mt-2">
            {
                input
            }
        </div>
    </div>
}