export default class Api {
    constructor(password) {
        this.BASE_URL = "https://i.umaevents.io";
        this.password = password;
    }

    async getHistory({
        page,
        expire,
        count,
        server
    }) {
        let searchParams = new URLSearchParams();
        if (page) {
            searchParams.set("page", page);
        }
        if (expire) {
            searchParams.set("expire", expire);
        }
        if (count) {
            searchParams.set("count", count);
        }
        if (server) {
            searchParams.set("server", server);
        }
        searchParams.set("password", this.password);
        let response = await fetch(`${this.BASE_URL}/api/history?${searchParams.toString()}`);
        let json = await response.json();
        return json;
    }

    async getHistoryString(id) {
        let response = await fetch(`${this.BASE_URL}/api/history/${id}?password=${this.password}`);
        let data = await response.json();
        return data;
    }

    async deleteHistory(id) {
        let response = await fetch(`${this.BASE_URL}/api/history/${id}?password=${this.password}`, {
            method: "DELETE"
        });
        let data = await response.json();
        return data;
    }

    async deleteHistorys(ids) {
        let response = await fetch(`${this.BASE_URL}/api/history?password=${this.password}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ids: ids
            })
        });
        let data = await response.json();
        return data;
    }
}